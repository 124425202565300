<template>
<div>
<!--  <organ-information></organ-information>-->
<!--  <treat-information></treat-information>-->
</div>
</template>

<script>
import {getOrganDetail} from "@/api";
// import OrganInformation from "../@/views/auth/organ"
// import TreatInformation from "@/views/treatmentUnit/organ"
 export default {
  name: "Authentication",
  // components:{
  //   OrganInformation,
  //   TreatInformation,
  // },
  data(){
    return{

    }
  },
  mounted() {
    if (JSON.parse(localStorage.getItem('cpis-web_userInfo'))) {
      this.organId = JSON.parse(localStorage.getItem('cpis-web_userInfo')).id
      this.reqOrganDetail()
    }else {
      this.$router.push('/login')
    }
    localStorage.removeItem('activeIndex');
  },
  methods:{
    // 获取机构信息
    async reqOrganDetail() {
      const self=this;
      const res = await getOrganDetail(this.organId)
      self.organ = res.biz
      if(self.organ.type == 'RESCUE'){
        this.$router.push('/unit/organ')
      }else {
        this.$router.push('/auth/organ')
      }
    },
  }
}
</script>

<style scoped>

</style>